<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewSymptoms">New Option</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
       >

       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
      <template #firstName="{item}">
        <td class='font-weight-bold'
          v-if="item.firstName && item.lastName"
        >{{ item.firstName }} {{item.lastName }}</td>
        <td v-else>--</td>
     </template>
     <template #followUpFirstName="{item}">
        <td class='font-weight-bold'
          v-if="item.followUpFirstName && item.followUpLastName"
        >{{ item.followUpFirstName }} {{item.followUpLastName }}</td>
        <td v-else>--</td>
     </template>
        <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                 
             <CDropdownItem :to="{name: 'Defect', params: {defectId: item.defectId,mode: 'view'}}">View</CDropdownItem>
                
                </CDropdown> 

        
        </td>
      </template>
      </CDataTable>

  </CCardBody>
  <div ref="modalArea"></div>
  <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
   { key: "firstName", label: "Requested By", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "followUpFirstName",label: "Followed By", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "dateOfInspection", _classes: 'font-weight-bold', _style: "min-width:200px" },
    { key: "accessibility", _classes: 'font-weight-bold', _style: "min-width:200px" },
    { key: "status", _classes: 'font-weight-bold', _style: "min-width:200px" },
     { key: "defectDescription", _classes: 'font-weight-bold', _style: "min-width:200px" },
     { key: "followUpDueDate", _classes: 'font-weight-bold', _style: "min-width:200px" },
      { key: "followUpDetails", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Defects",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getDefects() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/qms/defects/"+this.$route.params.qmsJobId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
   cancel() {
   
             this.$router.push({ name: "QmsJobs" });
    },
    navToNewSymptoms(){
       this.$router.push({ name: "Defects", params: {defectId: this.$route.params.defectId, mode: "new" } } );
    }
  },
  mounted() {
    this.getDefects();
  },
};
</script>